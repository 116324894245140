import Vue from "vue";
import App from "./App.vue";

import InputMask from 'vue-input-mask';
import "./registerServiceWorker";
import "./main.css";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import * as VueGoogleMaps from "vue2-google-maps";
import Vuelidate from "vuelidate";
import VueCookies from "vue-cookies";
import { registerComponents } from "./router";

Vue.use(VueCookies);
import "./plugins/fontawesome";
import VueMoment from "vue-moment";
const moment = require("moment");
require("moment/locale/th");

import dotenv from "dotenv";
dotenv.config();
Vue.prototype.$family_club = "123"

Vue.prototype.$liff = window.liff;

Vue.config.productionTip = false;
var google_map_api_key = "";

// Choose MODE (INHOME, DEV , PROD )
const MODE = process.env.VUE_APP_MODE
  ? `${process.env.VUE_APP_MODE}`
  : "DEV-SKL";

///////////////////////////////////////////////////////////////////////////////
///// PROD
if (MODE == "PROD") {
   
  //Setiing LINE Liff ID
  Vue.prototype.$liff_ID_Register = "1654170284-7OKk6QPe";
  Vue.prototype.$liff_ID_Disconnect = "1654170284-w5xMGzpR";
  Vue.prototype.$liff_ID_Setting = "1654170284-QlvOWNml";
  Vue.prototype.$liff_ID_Payment = "1654170284-AVO972QV";
  Vue.prototype.$liff_ID_Barcode = "1654170284-m2B7wZRl";
  Vue.prototype.$liff_ID_Detail = "1654170284-8V7MojZz";
  //// Phase 1
  // Vue.prototype.$liff_ID_Promotion = "1654170284-e4pEPJrq";
  //// Phase 2
  Vue.prototype.$liff_ID_Promotion = "1654170284-YeWqzLaX";
  Vue.prototype.$liff_ID_Promotion_Old = "1654170284-e4pEPJrq";
  ////
  Vue.prototype.$liff_ID_Document = "1654170284-XVMOnQ6B";
  Vue.prototype.$liff_ID_RentalRequestStatus = "1654170284-nd6a8VPE";

  Vue.prototype.$liff_ID_InstallmentList = "1654170284-R10y7NEo";
  Vue.prototype.$liff_ID_PaymentList = "1654170284-D5l7bYVQ";
  Vue.prototype.$liff_ID_SKLFamily = "1654170284-O2rp3DnX";
  Vue.prototype.$liff_ID_Buzzebees = "1654133345-R4LBpkP4";
  Vue.prototype.$liff_ID_Tracking = "1654170284-MX6Y8LG2";
  Vue.prototype.$liff_ID_Point = "1654170284-ko81W9RQ";

  google_map_api_key = "AIzaSyCuHTeRMTUCN1KP7K0ItJd0styCopBzWIo";
  Vue.prototype.$liff_ID_FindBranch = "1654170284-E5XQdDmL";
  Vue.prototype.$liff_ID_Map = "1654170284-eapbn8X3";
  Vue.prototype.$liff_ID_FeedBack = "1654170284-ynvXVrJP";

  Vue.prototype.$liff_ID_Broadcast = "1654170284-8p9alVvG";

  // Channel ID
  Vue.prototype.$liff_Channel_ID = "@fxi4480m";

  // Set Phone number for override
  // defult: null
  Vue.prototype.$OverrideMobileNumber = null;


  // API URL Settings
  Vue.prototype.$API_KEY_DEV_LINE_SKL = "S1112LIN05!";
  Vue.prototype.$API_DEV_LINE_SKL = "https://line-api.skl.co.th/api";
  Vue.prototype.$API_DEV_LINE_SKL_IMAGE_PATH = "https://line-api.skl.co.th";
  Vue.prototype.$API_DEV_SKL = "https://skl.co.th/api/v1";
  Vue.prototype.$PATH_SKL = "https://skl.co.th/family/point";
  Vue.prototype.$BASE_URL = "https://line-eservice.skl.co.th";

  Vue.prototype.$NIMBUS_TOKEN = "MDZ1MD23M1ZjMDc4Mx87M9g3MGIzMD5kMGF6";
  Vue.prototype.$BASE_URL_SALESFORCE_FEEDBACK = "";
}
///////////////////////////////////////////////////////////////////////////////
///// DEV
if (MODE == "DEV-SKL") {
  //Setiing LINE Liff ID
  Vue.prototype.$liff_ID_Register = "1654133345-LlzExny4";
  Vue.prototype.$liff_ID_Disconnect = "1654133345-4rGmwBkL";
  Vue.prototype.$liff_ID_Setting = "1654133345-72ENpLDG";
  Vue.prototype.$liff_ID_Payment = "1654133345-nm2W7bzJ";
  Vue.prototype.$liff_ID_Barcode = "1654133345-aee1yVbA";
  Vue.prototype.$liff_ID_Detail = "1654133345-QgE4qjdk";
  Vue.prototype.$liff_ID_Promotion = "1654133345-7xBODxbW";
  Vue.prototype.$liff_ID_Promotion_Old = "1654133345-WxPOXV5Q";
  Vue.prototype.$liff_ID_Document = "1654133345-mVQAvq7g";
  Vue.prototype.$liff_ID_RentalRequestStatus = "1654133345-pL6xy0Pa";
  Vue.prototype.$liff_ID_InstallmentList = "1654133345-KX8AYP3R";
  Vue.prototype.$liff_ID_PaymentList = "1654133345-nryg3MY1";
  Vue.prototype.$liff_ID_SKLFamily = "1654133345-aQqekgn4";
  Vue.prototype.$liff_ID_Buzzebees = "1654133345-R4LBpkP4";
  Vue.prototype.$liff_ID_Tracking = "1654133345-90or34KG";
  Vue.prototype.$liff_ID_Point = "1654133345-LkZ2YVRK";
  Vue.prototype.$liff_ID_Broadcast = "1654133345-M2P1v3qm";

  google_map_api_key = "AIzaSyCuHTeRMTUCN1KP7K0ItJd0styCopBzWIo";
  Vue.prototype.$liff_ID_FindBranch = "1654133345-XJaz9BJ8";
  Vue.prototype.$liff_ID_Map = "1654133345-Gr3m82DK";
  Vue.prototype.$liff_ID_FeedBack = "1654133345-Y7K2qjMN";

  // Channel ID
  Vue.prototype.$liff_Channel_ID = "@251cyyrv";

  // Set Phone number for override
  // defult: null
  // Vue.prototype.$OverrideMobileNumber = "0909912458"; // Jennie
  Vue.prototype.$OverrideMobileNumber = "0988944590"; // Khun Bee
  // Vue.prototype.$OverrideMobileNumber = "0836536262"; // MOSSSS
  // Vue.prototype.$OverrideMobileNumber = "0814028448"; // PREAW
  // Vue.prototype.$OverrideMobileNumber = "0873002662"; // LEO

  // API URL Settings
  Vue.prototype.$API_KEY_DEV_LINE_SKL = "S1112LIN05!";
  Vue.prototype.$API_DEV_LINE_SKL = "https://dev-skl-line-api.dosetech.co/api";
  Vue.prototype.$API_DEV_LINE_SKL_IMAGE_PATH =
    "https://dev-skl-line-api.dosetech.co";
  Vue.prototype.$API_DEV_SKL = "https://dev.skl.co.th/api/v1";
  Vue.prototype.$PATH_SKL = "https://skl.co.th/family/point";
  Vue.prototype.$BASE_URL = "https://dev-line-eservice.skl.co.th";

  Vue.prototype.$NIMBUS_TOKEN = "1DUzMDY0MDc2MDc2MDM0MDM1MDM2MDM3aM";
  Vue.prototype.$BASE_URL_SALESFORCE_FEEDBACK =
    "https://skl--skluat.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
}
///////////////////////////////////////////////////////////////////////////////
///// IN HOME
if (MODE == "INHOME") {
  //Setiing LINE Liff ID
  Vue.prototype.$liff_ID_Register = "";
  Vue.prototype.$liff_ID_Disconnect = "";
  Vue.prototype.$liff_ID_Setting = "";
  Vue.prototype.$liff_ID_Payment = "";
  Vue.prototype.$liff_ID_Barcode = "";
  Vue.prototype.$liff_ID_Detail = "";
  Vue.prototype.$liff_ID_Promotion = "";

  google_map_api_key = "AIzaSyCuHTeRMTUCN1KP7K0ItJd0styCopBzWIo";
  Vue.prototype.$liff_ID_FindBranch = "1654879040-ln3Key95";
  Vue.prototype.$liff_ID_Map = "";
  Vue.prototype.$liff_ID_FeedBack = "1654879040-BVb05r8O";

  // Channel ID
  Vue.prototype.$liff_Channel_ID = "@1654879040";

  // Set Phone number for override
  // defult: null
  Vue.prototype.$OverrideMobileNumber = "0988944590"; // Khun Bee

 

  // API URL Settings
  Vue.prototype.$API_KEY_DEV_LINE_SKL = "S1112LIN05!";
  Vue.prototype.$API_DEV_LINE_SKL = "https://dev.line.skl.co.th:18081/api";
  Vue.prototype.$API_DEV_LINE_SKL_IMAGE_PATH =
    "https://dev.line.skl.co.th:18081";
  Vue.prototype.$API_DEV_SKL = "https://dev.skl.co.th/api/v1";
  Vue.prototype.$PATH_SKL = "https://skl.co.th/family/point";

  Vue.prototype.$NIMBUS_TOKEN = "MDZ1MD23M1ZjMDc4Mx87M9g3MGIzMD5kMGF6";
  Vue.prototype.$BASE_URL_SALESFORCE_FEEDBACK =
    "https://skl--skluat.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
}
///////////////////////////////////////////////////////////////////////////////
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueMoment, { moment });
Vue.use(Vuelidate);



Vue.use(VueGoogleMaps, {
  load: {
    key: google_map_api_key,
    libraries: "places"
  }
});

Vue.component('input-mask', InputMask)


registerComponents(Vue);

// set loading
let globalData = new Vue({
  data: {
    $isLoadingAlpha: false
  }
});

Vue.mixin({
  computed: {
    $isLoadingAlpha: {
      get: function() {
        return globalData.$data.$isLoadingAlpha;
      },
      set: function(isLoadingAlpha) {
        globalData.$data.$isLoadingAlpha = isLoadingAlpha;
      }
    }
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
